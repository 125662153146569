<template>
  <div>
    <section class="block__video">
      <video controls autoplay loop>
        <source src="../assets/video.mp4" />
      </video>
    </section>
    <v-container class="mt-6">
      <v-row class="d-flex mt-6">
        <v-col md="6" cols="12" class="d-flex justify-md-end justify-center">
          <img src="../assets/img/logo-home1.png" alt="img" />
        </v-col>
        <v-col md="6" cols="12" class="d-flex justify-md-start justify-center">
          <img src="../assets/img/logo-home2.png" alt="" />
        </v-col>
      </v-row>
      <v-row class="mt-lg-5 mt-0">
        <p>{{ $t("home.text") }}</p>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.block__video {
  background-color: #000;
  margin: -40px;
  position: relative;
  padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
  height: 0;
  overflow: hidden;
}
video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
  outline-width: 0;
}
</style>
